// components/HeaderMenu.js
import { Col, Dropdown, Menu } from "antd";
import Link from "next/link";

const HeaderMenu = ({}) => {
  //   console.log(">>>>categories", categories);
  //   const sortItemsAlphabetically = (items) =>
  //     items.sort((a, b) => a.name.localeCompare(b.name));

  //   const handleIndustrySelect = (selectedIndustry) => {
  //     window.location.href = `/category/${selectedIndustry.slugName}`;
  //   };

  //   const renderMenu = (items) => (
  //     <Menu style={{ maxHeight: "500px", overflow: "auto" }}>
  //       {sortItemsAlphabetically(items).map((item) => (
  //         <Menu.Item key={item.id}>
  //           <Link
  //             href={`/category/${item.slugName}`}
  //             passHref
  //             onClick={() =>
  //               handleIndustrySelect({
  //                 id: item.id,
  //                 name: item.name,
  //                 slug: item.slugName,
  //               })
  //             }
  //           >
  //             {item.name}
  //           </Link>
  //         </Menu.Item>
  //       ))}
  //     </Menu>
  //   );

  return (
    <div className="header-menu">
      {/* <div className="header-menu-flex">
        <Col style={{ marginLeft: "120px" }}>
          <Dropdown
            overlay={renderMenu(categories.IndustrialSupplyMRO)}
            placement="bottomLeft"
          >
            <a className="new-dropdown">Industrial Supply & MRO</a>
          </Dropdown>
        </Col>
        <Col>
          <Dropdown
            overlay={renderMenu(categories.Electrical)}
            placement="bottomLeft"
          >
            <a className="new-dropdown">Electrical & Electronics</a>
          </Dropdown>
        </Col>
        <Col>
          <Dropdown
            overlay={renderMenu(categories.Plumbing)}
            placement="bottomLeft"
          >
            <a className="new-dropdown">Plumbing</a>
          </Dropdown>
        </Col>
        <Col>
          <Dropdown
            overlay={renderMenu(categories.HVAC)}
            placement="bottomLeft"
          >
            <a className="new-dropdown">HVAC & Refrigeration</a>
          </Dropdown>
        </Col>
        <Col style={{ marginRight: "120px" }}>
          <Dropdown
            overlay={renderMenu(categories.AllCategories)}
            placement="bottomLeft"
          >
            <a className="new-dropdown">All Categories</a>
          </Dropdown>
        </Col>
      </div> */}
      <div>Header Menu</div>
    </div>
  );
};

export default HeaderMenu;
