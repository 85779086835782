import { Navbar, Nav, Container, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Dropdown, Menu } from "antd";
import { useRouter } from "next/router";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect, useState, useRef } from "react";
import Link from "next/link";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "@/lib/store/hooks";
import { getTotals } from "@/lib/store/Slices/CartSlice";
import "./Header.css";
import Image from "next/image";
import {
  setQueryText,
  setIndustry,
  setCategory,
  typeRemove,
  industryRemove,
} from "@/lib/store/Slices/FilterSlice";
import Head from "next/head";
import { useAuth } from "@/context/AuthContext";
import { getUser } from "@/utils/auth";
import { MenuOutlined } from "@ant-design/icons";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import * as appConstants from "./../../utils/constants";

function Header() {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const cart = useAppSelector((state) => state.cart);
  const filterState = useAppSelector((state) => state.filter);
  const productState = useAppSelector((state) => state.product);
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [bodyClass, setBodyClass] = useState("");
  const myScrollableElement = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [isClient, setIsClient] = useState(false);
  const router = useRouter();
  const [processing, setProcessing] = useState(false);
  const [totalInventory, setTotalInventory] = useState(0); // State to store total inventory count
  const [isSearchBarVisible, setSearchBarVisible] = useState(false); // New state
  const [lastApiCallTime, setLastApiCallTime] = useState(null);
  const [Industrys, setIndustrys] = useState([]);
  const [Restaurant, setRestaurant] = useState([]);
  const [Electrical, setElectrical] = useState([]);
  const [Manufacturing, setManufacturing] = useState([]);
  const [AllCategories, setAllCategories] = useState([]);
  const [totalSKU, setTotalSKU] = useState(0);
  const [headerClassName, setHeaderClassName] = useState("");
  const [isActive, setActive] = useState("false");
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  // console.log(router.query);

  useEffect(() => {
    const storedUser = getUser();
    if (storedUser) {
      setUser(storedUser.user);
    }
  }, []);
  const Menuitems = [
    {
      key: "1",
      label: (
        <a target="" rel="" href="/client-asset-management">
          Client Asset Management
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/recycling-services">
          Recycling Services
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a target="" rel="noopener noreferrer" href="/appraisals">
          Appraisals
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a target="" rel="noopener noreferrer" href="/finders-program">
          Finders Fee Program
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a target="" rel="noopener noreferrer" href="/sell-to-us">
          Sell to Us
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a target="" rel="noopener noreferrer" href="/specialized-contracts">
          Specialized Contracts
        </a>
      ),
    },
  ];

  const menu = (
    <Menu>
      {Menuitems.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setProcessing(true);
        const response = await axios.get("/api/getHeaderCategories");
        setIndustrys(response.data.IndustrialSupplyMRO);
        setRestaurant(response.data.Restaurant);
        // console.log(response.data.Electrical);
        setElectrical(response.data.Electrical);
        setManufacturing(response.data.Manufacturing);
        setAllCategories(response.data.GetAllCategory);
        setTotalSKU(response.data.TotalItemCount);
        setProcessing(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setProcessing(false);
      }
    };

    fetchCategories();
  }, []);
  // }, [router]);

  const sortItemsAlphabetically = (items) => {
    return items.sort((a, b) => a.name.localeCompare(b.name));
  };

  const handleIndustrySelect = (selectedIndustry) => {
    if (filterState.type?.name && filterState.type?.name != "") {
      dispatch(setType({ id: 0, name: "" }));
      dispatch(typeRemove());
    }
    if (filterState.industry?.name && filterState.industry?.name != "") {
      dispatch(setIndustry({ id: 0, name: "", slugName: "" }));
      dispatch(industryRemove());
    }
    dispatch(setCategory(selectedIndustry));
    window.location.href = `/category/${selectedIndustry.slugName}`;
  };

  const RestaurentMenu = (
    <Menu style={{ maxHeight: "500px", overflow: "auto" }}>
      {sortItemsAlphabetically(Restaurant).map((item) => (
        <Menu.Item key={item.id}>
          <Link
            href={`/plumbing/${item.slugName}`}
            passHref
            onClick={() =>
              (window.location.href = `/plumbing/${item.slugName}`)
            }
          >
            {item.name}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  const ManufacturingMenu = (
    <Menu style={{ maxHeight: "500px", overflow: "auto" }}>
      {sortItemsAlphabetically(Manufacturing).map((item) => (
        <Menu.Item key={item.id}>
          <Link
            href={`/hvac-refrigeration/${item.slugName}`}
            passHref
            onClick={() =>
              (window.location.href = `/hvac-refrigeration/${item.slugName}`)
            }
          >
            {item.name}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
  const AllCategoriesMenu = (
    <Menu style={{ maxHeight: "500px", overflow: "auto" }}>
      {sortItemsAlphabetically(AllCategories).map((item) => (
        <Menu.Item key={item.id}>
          <Link
            href={`/category/${item.slugName}`}
            passHref
            onClick={() =>
              (window.location.href = `/category/${item.slugName}`)
            }
          >
            {item.name}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  const ElectircalMenu = (
    <Menu style={{ maxHeight: "500px", overflow: "auto" }}>
      {sortItemsAlphabetically(Electrical).map((item) => (
        <Menu.Item key={item.id}>
          <Link
            href={`/electrical-electronics/${item.slugName}`}
            passHref
            onClick={() =>
              (window.location.href = `/electrical-electronics/${item.slugName}`)
            }
          >
            {item.name}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  const BusinessandIndustrialMenu = (
    <Menu style={{ maxHeight: "500px", overflow: "auto" }}>
      {sortItemsAlphabetically(Industrys).map((item) => (
        <Menu.Item key={item.id}>
          <Link
            href={`/industrial-supply-mro/${item.slugName}`}
            passHref
            onClick={() =>
              (window.location.href = `/industrial-supply-mro/${item.slugName}`)
            }
          >
            {item.name}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSearch = () => {
    dispatch(setQueryText(searchQuery));
  };

  if (typeof window !== "undefined") {
  }
  const toggleSearchBar = () => {
    setSearchBarVisible(!isSearchBarVisible);
  };

  const handleSearchClickEvent = (e) => {
    // mapPayloadGetItems();
    e.preventDefault();
  };

  const handelLogout = () => {
    removeLocalStorage();
    logout();
    router.push("/Inventory");
  };
  function removeLocalStorage() {
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.selectedTabSession
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postalQuoteSelected
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postalCarrierName
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postQuotesRates
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.postalQuote);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.totalShippingCost
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.freightQuote);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.PPStorage);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.f_ratesRes);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.f_selectedODFLServiceCode
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.freightCarrierName
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.freightPickupDate
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.ActiveTab);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.CompletedTab);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.CompletedTab);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.shippingTotal
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.f_ODFLOptionValues
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.localPickup);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.forceLocalPickup
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.carriersLogs);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.carrierType);
  }

  const ToggleClass = () => {
    setActive(!isActive);
  };

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "fixed" && window.pageYOffset >= 100) {
      setHeaderClassName("fixede");
    } else if (headerClassName === "fixed" && window.pageYOffset < 100) {
      setHeaderClassName("");
    }
  };

  React.useEffect(() => {
    setIsClient(true);
    dispatch(getTotals());
  }, []);
  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value
  //  sessionStorage.setItem("totalItemCountFetched", "true");

  // Only access localStorage on client-side
  // let loginData = {};
  // if (isClient) {
  //   loginData = JSON.parse(localStorage.getItem("login")) || {};

  // }

  const handleLoginSignup = () => {
    const currentPath = router.asPath;
    router.push(`/login?redirect=${encodeURIComponent(currentPath)}`);
  };

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <header className={headerClassName}>
        <div className="topbar">
          <div className="container">
            <ul>
              <li className="inventory_no">
                <a href="#">
                  {" "}
                  {/* <Icon icon="streamline:shopping-bag-hand-bag-2-shopping-bag-purse-goods-item-products" /> */}
                  <p
                    className="inventory_no"
                    style={{
                      marginBottom: 0,
                      color: "white !important",
                      fontWeight: "normal",
                      fontSize: "14px",
                      textWrap: "nowrap",
                    }}
                  >
                    Total Inventory : {totalSKU !== 0 ? totalSKU : "Loading..."}
                  </p>
                </a>
              </li>
              <li className="email_icon">
                <a aria-label="Email Id" href="mailto:sales@kingsurplus.com">
                  {" "}
                  <i>sales@kingsurplus.com</i>
                </a>
              </li>
              <li>
                <a aria-label="Contact Number" href="tel:830-995-5000">
                  <i>830-995-5000</i>
                </a>
              </li>
              <li className="TopBarFacebook">
                <a
                  aria-label="Facebook Id"
                  target="_blank"
                  href="https://www.facebook.com/kingsurpluscompany"
                ></a>
              </li>
              {(() => {
                if (user && user !== null) {
                  return (
                    <li>
                      <a
                        className="logOut"
                        aria-label="Log out"
                        onClick={handelLogout}
                      >
                        {" "}
                        Log Out{" "}
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <a href="#" onClick={handleLoginSignup}>
                        LOGIN/SIGNUP
                      </a>
                    </li>
                  );
                }
              })()}
            </ul>
          </div>
        </div>
        {["lg"].map((expand) => (
          <Navbar
            style={{ flexDirection: "column" }}
            key={expand}
            bg=""
            expand={expand}
          >
            <Container>
              <Navbar.Brand href="/">
                <Image
                  src="/assets/images/logo.webp"
                  height={200}
                  width={200}
                  priority
                  alt="KingSurplus Logo"
                />
              </Navbar.Brand>
              {/* Add a search button with an icon */}
              {router.pathname === "/Inventory" && (
                <div className="search-button"></div>
              )}

              <Navbar.Toggle
                // aria-controls="basic-navbar-nav"
                aria-controls={`offcanvasNavbar-expand-lg`}
                aria-expanded={expanded ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? (
                  <MenuOutlined style={{ fontSize: "24px" }} />
                ) : (
                  <MenuOutlined style={{ fontSize: "24px" }} />
                )}{" "}
              </Navbar.Toggle>

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="custom-offcanvas"
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <a
                      className={`${router.pathname === "/" && "active"}`}
                      href="/"
                    >
                      Home
                    </a>

                    <a
                      title="Inventory"
                      className={`${
                        router.pathname === "/Inventory" && "active"
                      }`}
                      href="/Inventory"
                    >
                      Inventory
                    </a>
                    <Dropdown overlay={menu} placement="bottomLeft">
                      <a href="#" className="ourservice-btn">
                        Our Services{" "}
                      </a>
                    </Dropdown>

                    <div className="dropdown-menu show dropdown-menu-mobile">
                      <a
                        title=" Client Asset Management"
                        href="/client-asset-management"
                      >
                        Client Asset Management
                      </a>
                      <a title="Surplus Inventory" href="/Inventory">
                        Surplus Inventory
                      </a>
                      <a title="Recycling Services" href="/recycling-services">
                        Recycling Services{" "}
                      </a>
                      <a title="Appraisals" href="/appraisals">
                        Appraisals
                      </a>
                      <a title="Finders Fee Program" href="/finders-program">
                        Finders Fee Program
                      </a>
                      <a title="Sell to Us" href="/sell-to-us">
                        Sell to Us
                      </a>
                      <a
                        title="Specialized Contracts"
                        href="/specialized-contracts"
                      >
                        Specialized Contracts
                      </a>
                    </div>
                    <a
                      title="About Us"
                      className={`${
                        router.pathname === "/about-us" && "active"
                      }`}
                      href="/about-us"
                    >
                      About Us
                    </a>
                    <a
                      title="Blog"
                      className={`${router.pathname === "/blog" && "active"}`}
                      href="/blog"
                    >
                      Blog
                    </a>
                    <a
                      title="Contact Us"
                      className={`${
                        router.pathname === "/contact-us" && "active"
                      }`}
                      href="/contact-us"
                    >
                      Contact Us
                    </a>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
              <ul className="header-btn-wrap">
                <li>
                  <a
                    aria-label="Shopping Cart"
                    href="/shopping-cart"
                    className={`${
                      router.pathname === "/shopping-cart" && "active"
                    } cart-header`}
                  >
                    <Icon icon="akar-icons:cart" />
                    {cart.totalItem > 0 && (
                      <span className="cartCount">{cart.totalItem}</span>
                    )}
                  </a>
                </li>
                {/* {loginData !== null && loginData.login && ( */}
                {user && user !== null && (
                  <li>
                    <a
                      aria-label="Profile"
                      href="/Profile"
                      className={`${
                        router.pathname === "/Profile" && "active"
                      } cart-header`}
                    >
                      <Icon icon="mdi:user" />
                    </a>
                  </li>
                )}
              </ul>
            </Container>
            {/* <Container className="header-menu"> */}
          </Navbar>
        ))}
      </header>
    </>
  );
}

export default Header;
